import React from 'react';
import { graphql } from 'gatsby';
import ImageTextBlock from './imageTextBlock';

const TestimonialEntry = ({ item, position }) => {
    if (!item) {
        return null;
    }

    const { title, content, testimonialOptions } = item;
    return (
        <ImageTextBlock
            content={content}
            heading=""
            image={testimonialOptions.mainImage}
            position={position}
            linkOrPopup="none"
            style="standard"
        />
    );
};

export default TestimonialEntry;

export const query = graphql`
    fragment TestimonialEntryFragment on WpTestimonial {
        id
        title
        content
        testimonialOptions {
            fieldGroupName
            mainImage {
                sourceUrl
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 500, quality: 80, layout: CONSTRAINED)
                    }
                }
                mediaItemUrl
            }

            # gallery {
            #     sourceUrl
            #     altText
            #     mediaItemUrl
            #     localFile {
            #         childImageSharp {
            #             #thumb: gatsbyImageData(width: 340,height: 340, quality: 80, layout: CONSTRAINED)
            #             thumb: fluid(maxWidth: 340,height: 340, quality: 80) {
            #                 ...GatsbyImageSharpFluid
            #             }
            #             full: fluid(maxWidth: 1024) {
            #                 ...GatsbyImageSharpFluid
            #             }
            #         }
            #     }
            # }
        }
    }
`;

import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import TestimonialEntry from '../components/testimonialEntry';
import PageHeading from '../components/pageHeading';

const TestimonialsPage = ({ data }) => (
    <>
        <Seo
            title="Customer Testimonials"
            postSchema={JSON.parse(data?.wp?.seo?.contentTypes?.testimonial.schema.raw)}
        />
        <PageHeading
            title={data?.wp?.testimonialSettings?.section?.title}
            intro={data?.wp?.testimonialSettings?.section?.intro}
            image={data?.wp?.testimonialSettings?.section?.image}
            standoutHeading={!!data?.wp?.testimonialSettings?.section?.image}
        />
        {data.allWpTestimonial.nodes.map((item, i) => (
            <TestimonialEntry item={item} key={item.id} position={i % 2 ? 'right' : 'left'} />
        ))}
    </>
);

export default TestimonialsPage;

export const query = graphql`
    query GET_TESTIMONIALS($ids: [String]) {
        wp {
            # sectionSettings {
            #     section
            # }
            postTypeExtras {
                testimonial {
                    intro
                    title
                }
            }

            seo {
                contentTypes {
                    testimonial {
                        schema {
                            raw
                        }
                    }
                }
            }
            testimonialSettings {
                section {
                    title
                    intro
                    image {
                        sourceUrl
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 540, quality: 90, layout: CONSTRAINED)
                            }
                        }
                        mediaItemUrl
                    }
                }
            }
        }
        allWpTestimonial(filter: { id: { in: $ids } }, sort: { fields: menuOrder, order: ASC }) {
            nodes {
                ...TestimonialEntryFragment
            }
        }
    }
`;

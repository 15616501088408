import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Heading, Paragraph, Text } from 'grommet';
import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import { HeaderPopupContext } from '../context';
import bgImage from '../images/quoteBgW.svg';
import { createMarkup } from '../utils';
import { IImage, ILink } from '../utils/componentInterfaces';
import ActionButtons from './actionButtons';
import Button from './button';
import HtmlContent from './htmlContent';
import Link from './link';
import { MaxWidth } from './maxWidth';
import { Tag } from './tag';
import TagBox from './tagBox';
import { TagButton } from './TagButton';

interface Props {
    buttonText?: string;
    content: string;
    heading: string;
    intro?: string;
    link?: ILink;
    imageText?: string;
    imageLink?: ILink;
    image: IImage;
    position: string;
    style?: string;
    tag?: string;
    contentClass?: string;
    linkOrPopup: 'popup' | 'link' | 'none' | 'both';
    noWrap?: boolean;
}

const StyledImageWrap = styled.div`
    max-width: 425px;
`;

const StyledBox = styled(Box)`
    ${({ position }) => css`
        display: flex;
        flex-direction: colum;
        gap: 32px;

        @media (min-width: 992px) {
            flex-direction: ${position === 'right' ? 'row-reverse' : 'row'};
        }
    `}
`;
const StyledBoxImage = styled(Box)`
    ${() => css`
        max-wdith: 100%;

        @media (min-width: 992px) {
            max-width: 514px;
        }
    `}
`;
const StyledQuoteParagraph = styled(Paragraph)`
    background-image: url('${bgImage}');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 16% auto;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 40px;
    min-height: 52px;
`;

const TheBlock: FC<any> = ({
    buttonText,
    content,
    heading,
    link,
    image,
    position,
    intro,
    style,
    linkOrPopup,
    tag,
    contentClass,
    imageText,
    imageLink,
}) => {
    const { onOpen } = useContext(HeaderPopupContext);

    return (
        <MaxWidth relative>
            {tag && <Tag position={position}>{tag}</Tag>}
            <StyledBox position={position}>
                {image && (
                    <StyledBoxImage flex={{ shrink: 0, grow: 1 }} round="xsmall">
                        <TagBox alignContent="center" flex={{ shrink: 0, grow: 1 }}>
                            <StyledImageWrap>
                                {imageLink?.uri ? (
                                    <Link to="/testimonials">
                                        <GatsbyImage
                                            image={image?.localFile?.childImageSharp?.gatsbyImageData}
                                            alt={image.altText}
                                            className="img-item"
                                        />
                                    </Link>
                                ) : (
                                    <GatsbyImage
                                        image={image?.localFile?.childImageSharp?.gatsbyImageData}
                                        alt={image.altText}
                                        className="img-item"
                                    />
                                )}
                            </StyledImageWrap>
                            {imageText && (
                                <StyledQuoteParagraph size="xlarge">
                                    <em>{imageText}</em>
                                    {imageLink?.uri && <Link to="/testimonials"> ...read more</Link>}
                                </StyledQuoteParagraph>
                            )}
                        </TagBox>
                    </StyledBoxImage>
                )}

                <Box direction="column">
                    {heading && (
                        <Heading level="2" color="accent-2" margin={{ bottom: 'medium', top: 'none' }}>
                            {heading}
                        </Heading>
                    )}
                    {intro && (
                        <Heading level="3" color="accent-1" margin={{ bottom: 'medium', top: 'none' }}>
                            {intro}
                        </Heading>
                    )}
                    {intro && <Text size="large" dangerouslySetInnerHTML={createMarkup(intro)} />}
                    <HtmlContent
                        marginBottom
                        className={contentClass}
                        dangerouslySetInnerHTML={createMarkup(content)}
                    />

                    {buttonText && linkOrPopup !== 'popup' && link && (
                        <Button
                            label={<Text color="white">{buttonText}</Text>}
                            to={link.uri}
                            color="brand"
                            primary
                            reverse
                            alignSelf="start"
                        />
                    )}
                    {buttonText && linkOrPopup === 'popup' && (
                        <TagButton
                            primary
                            label={
                                <>
                                    <Text color="white" textAlign="center">
                                        {buttonText}
                                    </Text>
                                </>
                            }
                            onClick={onOpen}
                            color="accent-1"
                            alignSelf="start"
                        />
                    )}
                    {linkOrPopup === 'both' && <ActionButtons />}
                </Box>
            </StyledBox>
        </MaxWidth>
    );
};

const ImageTextBlock: FC<Props> = (props) => {
    const { style, noWrap } = props;

    return (
        <>
            {noWrap ? (
                <TheBlock {...props} />
            ) : (
                <Box
                    className={style === 'standard' ? 'light-1' : 'brand'}
                    pad={{ vertical: 'xlarge' }}
                    background={
                        style === 'standard'
                            ? null
                            : {
                                  color: 'brand',
                                  opacity: 0.1,
                                  dark: false,
                              }
                    }
                >
                    <TheBlock {...props} />
                </Box>
            )}
        </>
    );
};

export default ImageTextBlock;
